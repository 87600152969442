import { type Component, type ComponentOptions, createApp } from "vue";
import { assert } from "@utils/assertion";
import { i18n, setI18nLanguage } from "@/i18n/i18nSetup";
import { Language } from "@utils/type/type";

type Module = (rootElement: Element) => void;

const modules: { callback: Module; selector: string }[] = [];
const widgets = new Map<string, Component>();
const alreadyInitializedElements = new Set<Element>();
const initFunctions = new Set<() => void>();

export function registerModule(selector: string, callback: Module): void {
  modules.push({ selector, callback });
}

export function updateDom(rootElement: Document | Element): void {
  modules.forEach((module) => {
    rootElement.querySelectorAll(module.selector).forEach((domElement) => {
      if (!alreadyInitializedElements.has(domElement)) {
        alreadyInitializedElements.add(domElement);
        module.callback(domElement);
      }
    });
  });
}

export function gaInitGeneral(rootElement: Document | Element): void {
  /* eslint-disable-next-line */
  (window as unknown as any).$(rootElement).gaInitGeneral();
}

document.addEventListener("ga.dom.updated", function (event) {
  if (event.target instanceof Element) {
    updateDom(event.target);
  } else {
    updateDom(document);
  }
});

export function registerVueWidgetForJsp(name: string, component: Component): void {
  widgets.set(name, component);
}

export function registerGlobalInitFunction(fn: () => void): void {
  initFunctions.add(fn);
}

// Our scripts are being loaded with the `defer` keyword, which means the document will already be parsed when this runs.
// Maybe there is a cleaner and simpler solution to make sure all modules get registered first and then run once.
addEventListener("DOMContentLoaded", () => {
  initFunctions.forEach((fn) => fn());
  updateDom(document);

  if (widgets.size > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).vue = {
      mountWidget: async (name: string, selector: string, opt: ComponentOptions = {}): Promise<void> => {
        const component = widgets.get(name);
        assert(component !== undefined, `Component '${name}' has not been registered`);
        await setI18nLanguage(Language.getCurrentLanguage());
        createApp(component, opt).use(i18n).mount(selector);
      },
    };

    document.dispatchEvent(new CustomEvent("ga.design.ready", { bubbles: true }));
  }
});
